import React, { useEffect, useState } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Home = () => {
  const { username } = useSelector((state) => state.authState);

  useEffect(() => {
    document.title = "Home | Ledger ";
  }, []);

  return (
    <>
      <div className="container my-5" style={{ fontFamily: "cairo" }}>
        <div className="text-center">
          <h1>WEL COME</h1>
          <h5>MR. {username?.name}</h5>
        </div>

        <div className="container">
          <div className="row mt-5 g-4">
            <Link to={"/detail"} className="col-6 col-md-3 col-sm-6 ">
              <div
                style={{ backgroundColor: "#008183" }}
                className="card  mx-sm-1 p-3 click"
              >
                <div className="text-white text-center my-5">
                  <h4>Ledger</h4>
                </div>
              </div>
            </Link>

            <Link to={"/"} className="col-6 col-md-3 col-sm-6">
              <div
                style={{ backgroundColor: "#8EAC50" }}
                className="card  mx-sm-1 p-3"
              >
                <div className="text-white text-center my-5">
                  <h4>Up Coming</h4>
                </div>
              </div>
            </Link>
            <Link to={"/"} className="col-6 col-md-3 col-sm-6">
              <div
                style={{ backgroundColor: "#8EAC50" }}
                className="card  mx-sm-1 p-3"
              >
                <div className="text-white text-center my-5">
                  <h4>Up Coming</h4>
                </div>
              </div>
            </Link>
            <Link to={"/"} className="col-6 col-md-3 col-sm-6">
              <div
                style={{ backgroundColor: "#8EAC50" }}
                className="card  mx-sm-1 p-3"
              >
                <div className="text-white text-center my-5">
                  <h4>Up Coming</h4>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
