import "./App.css";
import Login from "./Modules/Login/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./Modules/ProtectedRoute";
import LoginVerify from "./Modules/Login/LoginVerify";
import Home from "./Modules/Home/Home";
import Detail from "./Modules/Detail/Detail";
import { useSelector } from "react-redux";
import Header from "./Modules/Header";
import Lorder from "./Modules/Lorder/Lorder";
import PdfView from "./Modules/PDF/PdfView";

function App() {
  const { userToken, loading } = useSelector((state) => state.authState);

  return (
    <>
      <HashRouter>
        <Toaster />
        {userToken ? (
          <>
            <Header />
          </>
        ) : null}

        {loading ? <Lorder /> : null}

        <Routes>
          <Route path="/" element={!userToken ? <Login /> : <Home />} />
          <Route path="/loginverify" element={<LoginVerify />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/detail" element={<Detail />} />
            <Route path="/pdfview" element={<PdfView />} />
          </Route>
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
