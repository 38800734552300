import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "./reducers";
import localStorage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

const enhancers = [
  applyMiddleware(
    thunkMiddleware,
    createLogger({
      collapsed: true,
    })
  ),
];

const enhancer = compose(...enhancers);

const persistConfig = {
  key: "root",
  storage: localStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let Store = createStore(persistedReducer, {}, enhancer);
  let persistor = persistStore(Store);
  return { Store, persistor };
};
