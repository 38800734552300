import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { LogOutAction } from "../redux/actions/authActons";

const Header = () => {
  const dispatch = useDispatch();

  window.$(document).click(function (event) {
    var clickover = window.$(event.target);
    var $navbar = window.$(".navbar-collapse");
    var _opened = $navbar.hasClass("in");
    if (_opened === true && !clickover.hasClass("navbar-toggle")) {
      $navbar.collapse("hide");
    }
  });

  const handleEntailmentRequest = (e) => {
    // e.preventDefault();
    window.location.reload(false);
    //do something...
  };

  return (
    <nav class="navbar navbar-expand-lg bg-body-tertiary sticky-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          LOGO
        </a>
        <button className="btn btn-info m-2">
          <i
            class="fa-solid fa-rotate-right"
            onClick={(e) => window.location.reload(false)}
          ></i>
        </button>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse in" id="navbarText">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <Link to={"/"} class="nav-link active" aria-current="page">
                Home
              </Link>
            </li>
            {/* <li class="nav-item">
              <a class="nav-link" href="#">
                Features
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">
                Pricing
              </a>
            </li> */}
          </ul>
          <span
            onClick={() => {
              if (window.confirm("Are You Sure?")) {
                dispatch(LogOutAction());
              }
            }}
            class="navbar-text"
          >
            Log Out
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Header;
