import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import axios from "axios";
import { LOADING, LOADING_ERROR } from "../../redux/actions/types";

const PdfView = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fileurl, setFileurl] = useState("");

  const { userToken } = useSelector((state) => state.authState);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch({
      type: LOADING,
    });

    var myHeaders = new Headers();
    myHeaders.append("If-Range", userToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer RGRrbr*3^[^fWvhQb=bfXcvRW^]=?fkhuy*^g"
    );

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(location.state.pdfurl, requestOptions)
      .then((response) => response.blob())
      .then((response) => {
        const url = window.URL.createObjectURL(response);
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "test");
        // document.body.appendChild(link);
        // link.click();
        // console.log(url);
        setFileurl(url);
        setLoading(false);
        dispatch({
          type: LOADING_ERROR,
        });
      })
      .catch((error) => console.log("error", error));
  }, []);

  const [pdfData, setPdfData] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDownload = async () => {
    dispatch({
      type: LOADING,
    });
    var myHeaders = new Headers();
    myHeaders.append("If-Range", userToken);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer RGRrbr*3^[^fWvhQb=bfXcvRW^]=?fkhuy*^g"
    );

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(location.state.pdfurl, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "document.pdf";
        a.click();
        URL.revokeObjectURL(url);
        dispatch({
          type: LOADING_ERROR,
        });
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      <button
        className="btn btn-info d-flex mx-auto"
        style={{ marginTop: "16px" }}
        onClick={handleDownload}
      >
        Download PDF
      </button>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          // display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1, width: "100%", maxWidth: "800px" }}>
          <Document file={fileurl} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} width={window.innerWidth} />
          </Document>
        </div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
    </div>
  );
};

export default PdfView;
