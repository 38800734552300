import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { LoginAction } from "../../redux/actions/authActons";
import { Pattern } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = "Login | Ledger ";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const mobile = data.get("mobile");

    if (mobile) {
      dispatch(LoginAction(mobile, navigate));
    }
  };

  return (
    <>
      {/* <div>
        <div className="bg-info p-2 rounded-circle"></div>
      </div> */}
      <button className="btn btn-info m-2">
        <i
          class="fa-solid fa-rotate-right"
          onClick={(e) => window.location.reload(false)}
        ></i>
      </button>

      {/* Spinner Start */}
      {/* <div
        id="spinner"
        className={`${
          LoginInLoading ? "show" : "hide"
        }  bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center`}
      >
        <div
          className="spinner-border text-primary"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
      {/* Spinner End */}
      <div
        className="d-flex align-items-center"
        style={{
          height: "100vh",
        }}
      >
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // alignItems: "center",
              }}
            >
              {/* <img
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "20px",
              }}
              src="assets/img/logo-dark.png"
              alt=""
              height={"70px"}
              width={"200px"}
            /> */}

              <Typography component="h1" variant="h5" mt={2}>
                Sign in
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }} //add this
                  type="number"
                  inputMode="numeric"
                  margin="normal"
                  required
                  fullWidth
                  id="mobile"
                  label="Your Whatsapp No."
                  name="mobile"
                  autoComplete="mobile"
                  autoFocus
                />

                <Button
                  className="bg-primary"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
                {/* <Grid container>
                <Grid item>
                  <Link to="/enquiry" variant="body2">
                    Don't have an account? Enquiry
                  </Link>
                </Grid>
              </Grid> */}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </div>
    </>
  );
}
