/* eslint-disable radix */
import axios from "axios";
import toast from "react-hot-toast";

import {
  LOGIN_TO_GET_A_TOKEN,
  LOGIN_LOADING,
  LOGIN_ERROR,
  LOG_OUT,
  LOADING_ERROR,
  LOADING,
  LOGIN,
  LOGIN_VERIFY,
  LEDGER,
} from "./types";

export const LogOutAction = (navigation) => async (dispatch) => {
  try {
    dispatch({
      type: LOG_OUT,
    });
  } catch (e) {
    console.log(e);
  }
};

export const LoginAction = (mobile, navigate) => (dispatch) => {
  dispatch({
    type: LOADING,
  });

  var data = {
    mobile: mobile,
  };

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://nts.dhyaravi.com/react_api/login",
    headers: {
      Authorization: "Bearer RGRrbr*3^[^fWvhQb=bfXcvRW^]=?fkhuy*^g",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      if (serverResponse?.success === 1) {
        navigate("/loginverify");
        dispatch({
          type: LOGIN,
          payload: mobile,
        });
        toast.success(serverResponse.message);
      } else {
        console.log(serverResponse);
        dispatch({
          type: LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch(LogOutAction());
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch(LogOutAction());
        }
      } else {
        alert(error);
      }
    });

  // var myHeaders = new Headers();
  // myHeaders.append(
  //   "Authorization",
  //   "Bearer RGRrbr*3^[^fWvhQb=bfXcvRW^]=?fkhuy*^g"
  // );

  // var formdata = new FormData();
  // formdata.append("mobile", "9601111194");

  // var requestOptions = {
  //   method: "POST",
  //   headers: myHeaders,
  //   body: formdata,
  //   redirect: "follow",
  // };

  // fetch("https://nts.dhyaravi.com/react_api/login", requestOptions)
  //   .then((response) => response.text())
  //   .then((result) => console.log(result))
  //   .catch((error) => console.log("error", error));
};

export const LoginVerifyAction = (mobile, otp, navigate) => (dispatch) => {
  dispatch({
    type: LOADING,
  });

  var data = {
    mobile: mobile,
    otp: otp,
  };

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://nts.dhyaravi.com/react_api/login/verify",
    headers: {
      Authorization: "Bearer RGRrbr*3^[^fWvhQb=bfXcvRW^]=?fkhuy*^g",
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: LOGIN_VERIFY,
          payload: serverResponse.token,
          userpayload: serverResponse.data,
        });
        toast.success(serverResponse.message);
      } else {
        dispatch({
          type: LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch(LogOutAction());
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch(LogOutAction());
        }
      } else {
        alert(error);
      }
    });
};

export const LedgerAction = () => (dispatch, getState) => {
  const {
    authState: { userToken },
  } = getState();

  dispatch({
    type: LOADING,
  });

  var dateobj = new Date();
  const date = dateobj.getDate();
  const month = dateobj.getMonth() + 1;
  const year = dateobj.getFullYear();
  console.log(`${year}-${month}-${date}`);

  var data = JSON.stringify({
    from: "1997-07-28",
    to: `${year}-${month}-${date}`,
  });

  console.log(data);

  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://nts.dhyaravi.com/react_api/home/get_ledger",
    headers: {
      "If-Range": userToken,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then((response) => {
      const serverResponse = response.data;
      console.log(serverResponse);
      if (serverResponse?.success === 1) {
        dispatch({
          type: LEDGER,
          payload: serverResponse,
        });
        toast.success(serverResponse.message);
      } else {
        dispatch({
          type: LOADING_ERROR,
        });
        toast.error(serverResponse.message);
      }
    })
    .catch((error) => {
      dispatch({
        type: LOADING_ERROR,
      });
      console.log(error);
      if (error.response) {
        let message = error.response.data.message;
        let status = error.response.status;
        if (status == 401) {
          alert("unauthorized");
          dispatch(LogOutAction());
        } else if (status == 404) {
          alert("Page Not Found");
          dispatch(LogOutAction());
        }
      } else {
        alert(error);
      }
    });
};
