import React, { useState } from "react";
import "./Detail.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LedgerAction } from "../../redux/actions/authActons";
import { Link, useNavigate } from "react-router-dom";
import DetailModal from "./DetailModal";

const Detail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ledgerlist } = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(LedgerAction());

    document.title = "Detail | Ledger ";
  }, []);

  const [visible, setVisible] = useState(false);

  return (
    <>
      <DetailModal />
      {ledgerlist?.data?.length === 0 ||
      (Array.isArray(ledgerlist?.data) && ledgerlist?.data?.length === 0) ? (
        <>
          {(Array.isArray(ledgerlist?.data) &&
            ledgerlist?.data?.length === 0) ||
          ledgerlist?.data?.length === 0 ? (
            <div
              className=" d-flex justify-content-center "
              style={{
                height: "100vh",
              }}
            >
              <h4 className="my-auto">Ledger Not Found</h4>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="container my-5 px-1" style={{ fontFamily: "cairo" }}>
          <div className="position-relative ">
            <Link
              style={{
                position: "fixed",
                top: 90,
                right: 0,
                backgroundColor: "#008183",
              }}
              onClick={() => {
                window.scrollTo({
                  top: document.body.offsetHeight,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              className="btn btn-lg  btn-lg-square "
            >
              <i className="bi bi-arrow-down" style={{ color: "#fff" }} />
            </Link>
          </div>
          <div className="">
            <div>
              <h1>WEL COME</h1>
              <h5>MR. {ledgerlist?.name}</h5>
            </div>
          </div>

          <table class="table my-5">
            <thead className="table-dark">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Debit</th>
                <th scope="col">Credit</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody>
              {ledgerlist?.data?.map((item) => (
                <tr>
                  <th
                    onClick={() =>
                      navigate("/pdfview", {
                        state: {
                          pdfurl: ` https://nts.dhyaravi.com/react_api/home/get_sales_pdf/${item.invoice}`,
                        },
                      })
                    }
                    scope="row"
                    style={{
                      backgroundColor:
                        item.type == "sale_invoice"
                          ? "#fcb6b6"
                          : item.type === "payment"
                          ? "#bcfcc7"
                          : item.type === "receipt"
                          ? "#e6d8ad"
                          : "#fff",
                    }}
                  >{`${item?.date} (${item?.type})`}</th>
                  <td
                    onClick={() =>
                      navigate("/pdfview", {
                        state: {
                          pdfurl: ` https://nts.dhyaravi.com/react_api/home/get_sales_pdf/${item.invoice}`,
                        },
                      })
                    }
                    style={{
                      backgroundColor:
                        item.type == "sale_invoice"
                          ? "#fcb6b6"
                          : item.type === "payment"
                          ? "#bcfcc7"
                          : item.type === "receipt"
                          ? "#e6d8ad"
                          : "#fff",
                    }}
                  >
                    {item?.debit}
                  </td>
                  <td
                    onClick={() =>
                      navigate("/pdfview", {
                        state: {
                          pdfurl: ` https://nts.dhyaravi.com/react_api/home/get_sales_pdf/${item.invoice}`,
                        },
                      })
                    }
                    style={{
                      backgroundColor:
                        item.type == "sale_invoice"
                          ? "#fcb6b6"
                          : item.type === "payment"
                          ? "#bcfcc7"
                          : item.type === "receipt"
                          ? "#e6d8ad"
                          : "#fff",
                    }}
                  >
                    {item?.credit}
                  </td>
                  <td
                    onClick={() =>
                      navigate("/pdfview", {
                        state: {
                          pdfurl: ` https://nts.dhyaravi.com/react_api/home/get_sales_pdf/${item.invoice}`,
                        },
                      })
                    }
                    style={{
                      backgroundColor:
                        item.type == "sale_invoice"
                          ? "#fcb6b6"
                          : item.type === "payment"
                          ? "#bcfcc7"
                          : item.type === "receipt"
                          ? "#e6d8ad"
                          : "#fff",
                    }}
                  >
                    {item?.balance}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div>
            <div className="border rounded m-2 fixed-bottom bg-light">
              <div className="d-flex">
                <h5 className="m-0 bg-info p-2 rounded-start">
                  Closing Balance
                </h5>
                <h5 className="m-0 p-2 ">{ledgerlist?.finalbalance}</h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Detail;
